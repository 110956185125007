// EquipeRbb.js
import React from 'react';
import './EquipeRbb.css';

const EquipeRbb = () => {
  const equipeData = [
    {
      nom: 'Elisabeth Zurbriggen',
      prenom: 'Elisabeth',
      role: 'Présidente',
      description:
        'Coordonne le projet, gère les contacts avec ASPH et d’autres associations. Elle supervise le programme et les contenus des missions à Conakry, réalise les dossiers pour les recherches de fonds. Elle organise également les Performances-lecture. Auparavant, elle s’occupait entre autres à Genève de la formation des enseignants dans des contextes interculturels.',
      fondateur: true,
    },
    {
      nom: 'Rosita Fibbi',
      prenom: 'Rosita',
      role: 'Secrétaire',
      description:
        'Soutient les activités de RBB en apportant ses connaissances du milieu associatif. Par ailleurs, elle étudie les questions liées à l’immigration en Suisse et est active dans les associations d’aide aux migrants à Genève.',
      fondateur: false,
    },
    {
      nom: 'Yves Magat',
      prenom: 'Yves',
      role: 'Trésorier',
      description:
        'S’occupe également de la communication, réalise les images et les vidéos. Auparavant journaliste international de la RTS, il a réalisé de nombreux reportages à l’étranger et a donné pendant 12 ans des formations à des journalistes de radios communautaires en Afrique, Brésil et Colombie.',
      fondateur: false,
    },
    {
      nom: 'Mamadou Sow',
      prenom: 'Mamadou',
      role: 'Conseiller',
      description:
        'Fondateur et président de 𝘭’𝘈𝘴𝘴𝘰𝘤𝘪𝘢𝘵𝘪𝘰𝘯 𝘥𝘦 𝘴𝘰𝘶𝘵𝘪𝘦𝘯 𝘢𝘶𝘹 𝘱𝘦𝘳𝘴𝘰𝘯𝘯𝘦𝘴 𝘷𝘪𝘷𝘢𝘯𝘵 𝘢𝘷𝘦𝘤 𝘶𝘯 𝘩𝘢𝘯𝘥𝘪𝘤𝘢𝘱 (𝘈𝘚𝘗𝘏) à Conakry. Depuis l’Italie où il réside, il est constamment en contact avec son équipe et assure les liens avec RBB. Il se base sur son expérience de vie dans un village de l’intérieur de Guinée puis dans la Cité de la Solidarité à Conakry.',
      fondateur: true,
    },
    {
      nom: 'Didier Prost',
      prenom: 'Didier',
      role: 'Responsable technique',
      description:
        'S’occupe des envois de matériel, de l’aménagement de l’atelier à Conakry et de la formation des réparateurs guinéens. Il s’appuie sur 30 ans d’expérience dans un grand atelier de réparation- adaptation-construction de fauteuils roulants à Genève dont il était le responsable. Il s’est également occupé de formation en lien avec la réinsertion professionnelle.',
      fondateur: false,
    },
    {
      nom: 'José Perejón',
      prenom: 'José',
      role: 'Technicien-orthopédiste',
      description:
        'Seconde son collègue Didier Prost dans les différentes tâches. Il réalise actuellement une formation complémentaire dans son domaine d’activités. Sa pratique du terrain au Mexique lui confère des compétences précieuses dans les pays en développement.',
      fondateur: true,
    },
  ];

  return (
    <div className="equipe-rbb-container">
      <header className="equipe-rbb-header">
        <h2 className="equipe-rbb-title">Équipe RBB</h2>
      </header>
      <div className="equipe-rbb-grid">
        {equipeData.map((membre, index) => (
          <div key={index} className="equipe-rbb-column">
            <img
              className="equipe-rbb-image"
              
              src={`/iconeMembre.png`}
              //src={`path/to/${membre.nom.replace(/\s+/g, '-')}.jpg`}
              alt={membre.nom}
            />
            <div className="equipe-rbb-role">
               {membre.nom}<br/><br/>{membre.role}
              {membre.fondateur && <span></span>}
            </div>
            <div className="equipe-rbb-paragraph">{membre.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EquipeRbb;
