import React, { useState, useEffect } from 'react';
import './Story.css';
import image from './couverture_shadow.jpeg';
import { Link } from 'react-router-dom';

const Story = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='storyContainer'>
      <div className="innerContainer">
        <div className="image-container">
          <img className='couvertureImage' src={image} alt="Livre" />
        </div>
        <div className="text-containerStory">
          <h2 className='titleStory'>
            La route à bout de bras : <Link to="/le-livre">le livre</Link>
          </h2>
          <p className="italic-textStory">
            « Un jour, comme je racontais mon histoire à un de mes cousins, il m'a dit qu'elle était incroyable et qu'il fallait la faire connaître. Alors je me suis mis en tête de la transmettre pour témoigner de ma situation de migrant handicapé. »
          </p>
          <p className="signatureStory">Mamadou Sow</p>
          <p className='lienCommercial'>
            Le récit de Mamadou Sow recueilli par Elisabeth Zurbriggen<br />
            est disponible en bibliothèques, en librairies et aux éditions <a className='migrilude' href="https://www.migrilude.com/fr/accueil/108-la-route-a-bout-de-bras-9791097542139.html" target="_blank">Migrilude</a>
          </p>
        </div>
      </div>
      <div className="cartoucheMaterial">
        <div className="cartoucheContent">
          <div className='MaterialTextDiv'>
            <h3>Matériel pédagogique</h3>
            <p>
              En choisissant <em>La route à bout de bras</em> comme lecture suivie, les enseignant·e·s peuvent facilement développer différents thèmes d'actualité (migration, handicap, droits humains, alphabétisation, accueil, solidarité, ...). Le contenu et la forme en abécédaire du récit se prêtent particulièrement bien à de nombreuses activités d'enseignement/apprentissage. Le texte, rédigé par mots-clés, permet également une lecture différenciée.
            </p>
            <a
              href="./dossierPedagogique/DossierPedagogiqueComplet.pdf"
              download="dossier_pedagogique.pdf"
              className="download-button-material"
            >
              Télécharger le dossier pédagogique complet en PDF (25 pages)
            </a>
          </div>
          {!isMobile && (
            <div className='videoPresentation'>
              <iframe width="390" height="215" src="https://www.youtube.com/embed/gJcfnYVbhs4?si=xGMVTKdhJo16Ykvc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Story;
