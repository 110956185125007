import React from 'react';
import Header from './Header';
import Title from './Title';
import Story from './Story';
import AccueilAssociation from './AccueilAssociation';
import PerformanceLecture from './PerformanceLecture';
import DossierPedagogique from './DossierPedagogique';
import LastEvent from './LastEvent';
import './Home.css';

const Home = () => {

  return (<>
  <LastEvent />
    <Header />
    <AccueilAssociation />
    <Story />
    <PerformanceLecture />
    </>
  );
}

export default Home;