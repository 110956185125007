import React from 'react';
import './Objectifs.css';
import Activite from './Activite'

const Objectifs = () => {
  return (<>
    <div className="objectifs-container">
      <div className="objectifs-column">
        <h2>Nos activités</h2>
        <ul>
          <li>
            <h3>Rechercher des fauteuils roulants à Genève</h3>
            <p>
              Des changements de pièces sont parfois effectués pour s’adapter aux routes guinéennes,
              par exemple pose de pneus pleins. Nous récoltons aussi des béquilles.
            </p>
          </li>
          <li>
            <h3>Envoyer les fauteuils et des béquilles à Conakry</h3>
            <p>Nous organisons plusieurs envois par année sont réalisés dans des conteneurs acheminés par des cargos. Nous obtenons régulièrement des prix de faveur vu le caractère humanitaire du projet.</p>
          </li>
          <li>
            <h3>Aménager et équiper un atelier à Conakry</h3>
            <p>L’atelier de réparation-adaptation de fauteuils roulants est monté sur place avec la participation de RBB et ASPH.</p>
            <ul>
              <li>
                <h3>Former des réparateurs</h3>
                <p>Nos techniciens bénévoles forment des binômes composés d’un réparateur valide et d’une personne porteuse de handicap.</p>
              </li>
      
            </ul>
          </li>
         
        </ul>
      </div>
      <div className="objectifs-column">
        <ul>
          <li>
            <h3>Objectifs</h3>
            <h3>
            Aménager l’atelier de réparation/adaptation de Conakry et l’équiper en matériel et outillage.
            </h3>
          </li>
          <li>
            <h3>Poursuivre la formation pour permettre à des binômes (valides et porteurs de handicap) de devenir le plus rapidement possible des réparateurs indépendants.</h3>
          </li>
          <li>
            <h3>Ouvrir progressivement l’aide aux personnes vivant à l’intérieur du pays.</h3>
          </li>
          <li>
            <h3>Réaliser des handybike à Conakry adaptables aux fauteuils (3ème roue motrice avec pédalier manuel).</h3>
          </li>
          <li>
            <h3>Fabriquer des fauteuils roulants à Conakry mieux adaptés aux réalités de la Guinée (objectif à plus long terme).</h3>
          </li>
        </ul>
      </div>
      {/*
      <div className="objectifs-column">
        <ul>
          <li>
            <h3>Suite du Projet</h3>
            <h3>
              Poursuivre l’aménagement de l’atelier de réparation/adaptation de Conakry et son équipement en matériel et outillage.
            </h3>
          </li>
          <li>
            <h3>Prolonger la formation pour permettre à des binômes (valides et porteurs de handicap) de devenir le plus rapidement possible des réparateurs indépendants.</h3>
          </li>
          <li>
            <h3>Ouvrir l’aide progressivement aux personnes vivant à l’intérieur du pays.</h3>
          </li>
          <li>
            <h3>Réaliser des handybike à Conakry adaptables aux fauteuils (3ème roue motrice avec pédalier manuel).</h3>
          </li>
          <li>
            <h3>Fabriquer des fauteuils roulants à Conakry mieux adaptés aux réalités de la Guinée (objectif à plus long terme).</h3>
          </li>
        </ul>
      </div>
  */}
    </div>
   <Activite />
    </>
  );
};

export default Objectifs;
