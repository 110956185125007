import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Activite.css';

const Activite = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const fromPreviousComponent = urlParams.get('fromPreviousComponent');

    if (fromPreviousComponent) {
      const element = document.getElementById('2emeMission');
      if (element) {
        // Force le défilement vers le haut avec un décalage négatif de 50 pixels
        window.scrollTo({ top: element.offsetTop - 50, behavior: 'smooth' });
      }
    }
  }, [location.search]);

  const [isGreaterThan900, setIsGreaterThan900] = useState(window.innerWidth > 900);

  useEffect(() => {
    const handleResize = () => {
      setIsGreaterThan900(window.innerWidth > 900);
    };

    // Add event listener to update the state when the window is resized
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <>
      <div className="objectifs-container">
        <div className="objectifs-column">
          <h2>Nos activités</h2>
          <ul>
            <li>
              <h3>Rechercher des fauteuils roulants à Genève</h3>
              {isGreaterThan900 ? (
                <p>
                  Des changements de pièces sont parfois effectués pour s’adapter aux routes guinéennes,
                  par exemple pose de pneus pleins. Nous récoltons aussi des béquilles.
                </p>
              ) : (
                <>
                  Des changements de pièces sont parfois effectués pour s’adapter aux routes guinéennes,
                  par exemple pose de pneus pleins. Nous récoltons aussi des béquilles.
                </>
              )}
            </li>
            <li>
              <h3>Envoyer des fauteuils et des béquilles à Conakry</h3>
              {isGreaterThan900 ? (
                <p>
                  Nous organisons plusieurs envois par année dans des conteneurs acheminés par des cargos.
                  Nous obtenons régulièrement des prix de faveur vu le caractère humanitaire du projet.
                </p>
              ) : (
                <>
                  Nous organisons plusieurs envois par année dans des conteneurs acheminés par des cargos.
                  Nous obtenons régulièrement des prix de faveur vu le caractère humanitaire du projet.
                </>
              )}
            </li>
            <li>
              <h3>Aménager et équiper un atelier à Conakry</h3>
              {isGreaterThan900 ? (
                <p>
                  L’atelier de réparation-adaptation de fauteuils roulants est monté sur place avec la participation
                  de RBB et ASPH.
                </p>
              ) : (
                <>
                  L’atelier de réparation-adaptation de fauteuils roulants est monté sur place avec la participation
                  de RBB et ASPH.
                </>
              )}
              <ul>
                <li>
                  <h3>Former des réparateurs</h3>
                  {isGreaterThan900 ? (
                    <p>
                      Nos techniciens bénévoles forment des binômes composés d’un réparateur valide et d’une personne
                      porteuse de handicap.
                    </p>
                  ) : (
                    <>
                      Nos techniciens bénévoles forment des binômes composés d’un réparateur valide et d’une personne
                      porteuse de handicap.
                    </>
                  )}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="objectifs-column">
          <ul>
            <li>
              <h3>Objectifs</h3>
            </li>
            <li>
              <h3>Ouvrir progressivement l’aide aux personnes vivant à l’intérieur du pays.</h3>
            </li>
            <li>
              <h3>Réaliser des handybikes à Conakry adaptables aux fauteuils (3ème roue motrice avec pédalier manuel).</h3>
            </li>
            <li>
              <h3>Fabriquer des fauteuils roulants à Conakry mieux adaptés aux réalités de la Guinée (objectif à plus long terme).</h3>
            </li>
          </ul>
        </div>
      </div>

      <div className="activite-container" id="3emeMission">
        <div className="activite-text">
          <div className="mission-conakry">
            <h2>3ème mission à Conakry </h2>
            {isMobile ? (
              <div className="h2Margin">
                En avril 2024, Abdou Yassine, employé érythréen chez Degonda Rehab S.A. à Genève, a séjourné à Conakry pour aider à former les stagiaires de l'atelier de réparation de fauteuils roulants.
              </div>
            ) : (
              <p>
                En avril 2024, Abdou Yassine, employé érythréen chez Degonda Rehab S.A. à Genève, a séjourné à Conakry pour aider à former les stagiaires de l'atelier de réparation de fauteuils roulants.
              </p>
            )}
          </div>
        </div>

        
  {isMobile ? (
    <div className="video-container">
    <video
      title="Vidéo de nos activités"
      src="./YASSINE.mp4"
      controls
      className="video-iframe-replacement"
    >
      Votre navigateur ne supporte pas la lecture de vidéos.
    </video>
     </div>
  ) : (
    <div className="video-container-video">
    <video
      title="Vidéo de nos activités"
      src="./YASSINE.mp4"
      controls
      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
    >
      Votre navigateur ne supporte pas la lecture de vidéos.
    </video>
    </div>
  )}
</div>   





      <div className="activite-container" id="2emeMission">
        <div className="activite-text">
          <div className="mission-conakry">
            <h2>2ème mission à Conakry </h2>
            {isMobile ? (
              <div className="h2Margin">
                Fin 2023, trois membres de RBB ont travaillé un mois à Conakry. Ils ont aménagé le nouvel atelier avec l’aide
                de ASPH, poursuivi la formation de réparateurs de fauteuils roulants, organisé une grande distribution de
                fauteuils lors de la journée internationale des personnes vivant avec un handicap, visité d'autres
                associations à Conakry, … La suite de la formation est déjà prévue pour 2024.
              </div>
            ) : (
              <p>
                Fin 2023, trois membres de RBB ont travaillé un mois à Conakry. Ils ont aménagé le nouvel atelier avec l’aide
                de ASPH, poursuivi la formation de réparateurs de fauteuils roulants, organisé une grande distribution de
                fauteuils lors de la journée internationale des personnes vivant avec un handicap, visité d'autres
                associations à Conakry, … La suite de la formation est déjà prévue pour 2024.
              </p>
            )}
          </div>
        </div>

        <div className="video-container">
          {isMobile ? (
            <iframe
              title="Vidéo de nos activités"
              src="https://player.vimeo.com/video/947322836"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <iframe
              title="Vidéo de nos activités"
              src="https://player.vimeo.com/video/947322836"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ height: '50%' }}
            ></iframe>
          )}
        </div>
      </div>

      <div className="activite-container">
        <div className="activite-text">
          <div className="mission-conakry">
            <h2 className="h2Margin">1ère mission à Conakry</h2>
            {isMobile ? (
              <div className="h2Margin">
                En avril 2023, quatre membres de RBB se sont rendus à Conakry pour rencontrer leurs partenaires,
                évaluer les distributions, commencer à mettre sur pied un atelier de réparation/adaptation de fauteuils
                roulants et donner une formation de base aux réparateurs.
              </div>
            ) : (
              <p>
                En avril 2023, quatre membres de RBB se sont rendus à Conakry pour rencontrer leurs partenaires,
                évaluer les distributions, commencer à mettre sur pied un atelier de réparation/adaptation de fauteuils
                roulants et donner une formation de base aux réparateurs.
              </p>
            )}
          </div>
        </div>

        <div className="video-container">
          {isMobile ? (
            <iframe
              title="Vidéo de nos activités"
              src="https://player.vimeo.com/video/888216916"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <iframe
              title="Vidéo de nos activités"
              src="https://player.vimeo.com/video/888216916"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ height: '50%' }}
            ></iframe>
          )}
        </div>
      </div>
    </>
  );
}

export default Activite;
