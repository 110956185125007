import React, { useState, useEffect } from "react";
import './AccueilAssociation.css';
import { Link } from 'react-router-dom';

const AccueilAssociation = () => {
  const [showFullText, setShowFullText] = useState(window.innerWidth >= 900);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
      setShowFullText(window.innerWidth >= 900);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="two-column-section">
      <div className="innerContainer">
        <div className="left-column">
          {windowWidth >= 900 ? (
            <h2 className='titre'>Une histoire de courage et de solidarité</h2>
          ) : (
            <h2 className="titreSoutienAssociation">Une histoire de courage et de solidarité</h2>
          )}
          {isMobile ? (
            <div className="textPAccueil">
              L’association <strong><em>La route à bout de bras (RBB)</em></strong> est née sous l'impulsion de Mamadou Sow, réfugié handicapé des jambes qui a surmonté d'immenses difficultés pour venir de Guinée en Europe à la force des bras. 
              Après avoir publié un livre racontant sa vie à Conakry et son dangereux voyage, Mamadou Sow a voulu venir en aide à des personnes à mobilité réduite en Guinée. Depuis Aoste, en Italie, où il réside actuellement, il a alors créé en Guinée <em>l’Association de soutien aux personnes vivant avec un handicap (ASPH)</em>. Elle est le principal partenaire de l’association <em>La route à bout de bras (RBB)</em>.
            </div>
          ) : (
            <p className="textArea">
              L’association <strong><em>La route à bout de bras (RBB)</em></strong> est née sous l'impulsion de Mamadou Sow, réfugié handicapé des jambes qui a surmonté d'immenses difficultés pour venir de Guinée en Europe à la force des bras. 
              Après avoir publié un livre racontant sa vie à Conakry et son dangereux voyage, Mamadou Sow a voulu venir en aide à des personnes à mobilité réduite en Guinée. Depuis Aoste, en Italie, où il réside actuellement, il a alors créé en Guinée <em>l’Association de soutien aux personnes vivant avec un handicap (ASPH)</em>. Elle est le principal partenaire de l’association <em>La route à bout de bras (RBB)</em>.
            </p>
          )}
        </div>
        <div className="right-column">
          <div className="videoContainer">
            <iframe
              src="https://player.vimeo.com/video/888230577"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Vidéo de l'association"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccueilAssociation;
