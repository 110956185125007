import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "./components/Menu";
import Home from "./components/Home";
import Association from './components/Association'
import HistoireAssociation from './components/HistoireAssociation'
import Membres from './components/Membre'
import DevenirMembre from './components/DevenirMembre'
import GalleryPage from './components/GalleryPage'
import Contact from './components/Contact'
import Objectifs from "./components/Objectifs";
import Activite from "./components/Activite"
import LeLivre from "./components/LeLivre"
import Participer from "./components/Participer";
import EquipeRbb from "./components/EquipeRbb";
import Partenaire from "./components/Partenaire";
import Soutiens from "./components/Soutiens";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Menu />}>
          <Route index element={<Home />} />
          <Route path="/objectifs" element={<Objectifs />}/>
          <Route path="Association" element={<Association />} />
          <Route path="/association/equiperbb" element={<EquipeRbb />} />
          <Route path="/association/partenaire" element={<Partenaire />} />
          <Route path="/association/soutiens" element={<Soutiens />} />
          <Route path="/association/membres" component={Membres} />
          <Route path="/devenir-membre" element={<DevenirMembre />} />
          <Route path="/activite" element={<Activite />} />
          <Route path="/galerie" element={<GalleryPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/le-livre" element={<LeLivre />} />
          <Route path="/participer" element={<Participer />} />
      
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);