import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact">
      <h2>Contactez-nous</h2>
      <div className="email">
        <h2>E-mail</h2>
        <a href="mailto:larouteaboutdebras@gmail.com">larouteaboutdebras@gmail.com</a>
      </div>
      <hr class="custom-hr"/>
      <div className="address">
        <h2>Adresse</h2>
        <p>Chemin des Voirets 37<br/>
        1228 Plan-les-Ouates<br/>
        Suisse</p>
      </div>
      
      <hr class="custom-hr"/>
      <div className="social">
        <h2>Suivez-nous sur  <a href="https://www.facebook.com/Larouteaboutdebras" target="_blank" rel="noopener noreferrer">Facebook</a></h2>
       
      </div>
      
      <hr class="custom-hr"/>
      <div className="bank-details">
        <h2>Coordonnées bancaires</h2>
        <p>Nom de l'association : La route à bout de bras</p>
        <p>Adresse : Chemin des Voirets 37</p>
        <p>Banque Cantonale de Genève (BCGE)</p>
        <p>IBAN : CH85 0078 8000 0508 6172 9</p>
        <p>BIC/SWIFT : BCGECHGGXXX</p>
        <p>Clearing : 788</p>
      </div>
    </div>
  );
}

export default Contact;
