import React from 'react';
import { Link } from 'react-router-dom';
import './LastEvent.css';

const LastEvent = () => {
  return (
    <div className="NouvelAtelier">
      <div className="innerContainer">
        <div className="contentContainer">
          <h2>Dernier événement</h2>
          <p className='textLastEvent'>
            <b>Juillet 2024: Distribution de matériel dans l'intérieur de la Guinée </b> :<br />
            <br />
            Après des dons de fauteuils roulants pour la Guinée forestière, une distribution a été organisée à Mamou avec l'aide de nos partenaires de l'<b><i>Association de soutien aux personnes vivant avec un handicap</i></b> (ASPH). 

            23 personnes à mobilité réduite ont reçu gratuitement un fauteuil et 10 personnes amputées des béquilles.

            Les besoins à l'intérieur du pays sont encore plus importants qu'à Conakry, la capitale.
          </p>
        </div>
        <div className="videoContainer">
          <video
            controls
            width="630"
            height="360"
            style={{
              maxWidth: '570px',
            }}
          >
            <source src="/Mamou.mp4" type="video/mp4" />
            Votre navigateur ne supporte pas la balise vidéo.
          </video>
        </div>
      </div>
    </div>
  );
}

export default LastEvent;