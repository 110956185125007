import React, { useState, useEffect } from 'react';
import './Menu.css';
import { Outlet, Link, NavLink } from 'react-router-dom';
import facebookLogo from './facebookLogo.png'; // Remplacez le chemin par l'emplacement réel de l'image
import Title from './Title';

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setActiveSubMenu(null); // Fermer le sous-menu actif
  };

  const toggleSubMenu = (submenu) => {
    setActiveSubMenu(activeSubMenu === submenu ? null : submenu);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
      setIsMenuOpen(false);
      setActiveSubMenu(null);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <nav className={`menu ${isMenuOpen && isMobile ? 'open' : ''}`}>
        <ul className={`menu-list ${isMobile && isMenuOpen ? 'open' : ''}`}>
          <li><Link to="/" onClick={closeMenu}>Accueil</Link></li>
          <li>
            <NavLink to="/association/equiperbb" activeClassName="active" onClick={() => toggleSubMenu("association")}>
              Association
            </NavLink>
            <ul className={`submenu ${activeSubMenu === "association" ? 'active' : ''}`}>
              <li><NavLink to="/association/equiperbb" onClick={closeMenu}>L’équipe RBB</NavLink></li>
              <li><NavLink to="/association/partenaire" onClick={closeMenu}>Partenaire</NavLink></li> 
              <li><NavLink to="/association/soutiens" onClick={closeMenu}>Nos soutiens</NavLink></li> 
            </ul>
          </li>
          <li><Link to="/activite" onClick={closeMenu}>Activités</Link></li>
          <li><Link to="/participer" onClick={closeMenu}>Participer</Link></li>
          <li><Link to="/galerie" onClick={closeMenu}>Galerie</Link></li>
          <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
        </ul>
        {isMobile && (
  <button className="menu-toggle" onClick={toggleMenu}>
    Menu
  </button>
)}
      </nav>
      
      <Title /> 
      <Outlet />
    </>
  );
}

export default Menu;
