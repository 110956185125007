import React from 'react';

import Gallery from './Gallery';
const GalleryPage = () => {

  return (<>
    <Gallery/>
    </>
  );
}

export default GalleryPage; 