import React from 'react';
import './Partenaire.css';

const Partenaire = () => {
  return (
    <div className="partenaire-container">
      <header className="partenaire-header">
        <h1 className="partenaire-title">Nos Partenaires</h1>
      </header>
      <section className="textHeaderContainer">
      <em> L’Association de Soutien aux Personnes Vivant avec un Handicap (ASPH) </em>est notre principal partenaire en Guinée. Présidée à distance depuis l'Italie par Mamadou Sow, <em>ASPH</em> est composée d’une équipe extrêmement sérieuse et dévouée.
        Son vice-président, Alpha Mamoudou Sow, s’occupe de la comptabilité et rend des comptes à l’association<em> La Route à Bout de Bras</em>. Cette dernière envoie de Genève, au fur et à mesure de l’avancée du projet et selon un budget préétabli, une partie de l’argent reçu de différents dons et fondations.
      </section>
      <section className="partenaire-content">
        <div className="partenaire-info">
          <img className="partenaire-image" src="/partenaire.jpg" alt="Partenaire" />
        </div>
        <div className="partenaire-activities">
          <h2>Les activités de ASPH :</h2>
          <ul className="activities-list">
            <li>Identifier, sans distinction ethnique, des personnes à mobilité réduite particulièrement démunies nécessitant un fauteuil roulant ou des béquilles.</li>
            <li>Repérer des enfants qui ne peuvent pas sortir de chez eux par manque de fauteuil.</li>
            <li>Réceptionner tous les envois et organiser les transferts du port de Conakry à l’atelier.</li>
            <li>Gérer les distributions de matériel et faire un retour à RBB. Pour chaque fauteuil distribué, envoi d’une photo ou d’une vidéo.</li>
            <li>Participer à l’aménagement de l’atelier.</li>
            <li>Recruter des personnes valides et porteuses de handicap pouvant travailler à la concrétisation du projet.</li>
            <li>Adapter les fauteuils à la taille et au handicap de chaque bénéficiaire.</li>
            <li>Gérer et administrer l’atelier.</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Partenaire;
  