import React, { useState, useEffect } from "react";
import './PerformanceLecture.css';
import logo1 from './banner.png';

const PerformanceLecture = () => {
  const [showFullText, setShowFullText] = useState(window.innerWidth >= 900);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
      setShowFullText(window.innerWidth >= 900);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const videoWidth = isMobile ? "90%" : "530";
  const videoHeight = isMobile ? "90%" : "315";

  return (
    <>
      <div className="performanceContainer">
      <h2 className="titrePerformanceLecture">La route à bout de bras : Performance-lecture</h2>
        <div className="innerContainer">
          <div className={`activite-video ${!showFullText ? "small-video" : ""}`}>
            
            <iframe 
        width={videoWidth}
        height={videoHeight}
        src="https://www.youtube.com/embed/Mg0sExLJC7c" 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen
      ></iframe>{isMobile && (
              <div className="lectureP">
                Suite au succès du livre, récit de vie plein d’espoir et d’une énergie
                communicative, une Performance-lecture a été réalisée par la comédienne
                Lorette Andersen. Elle a condensé le récit de Mamadou Sow et le transmet
                avec brio en 40 minutes au public. Mamadou Sow, présent en bord de scène,
                répond ensuite aux questions de l’auditoire et explique comment il vient en
                aide à des personnes handicapées de son pays.
              </div>
            )}
          </div>
          {!isMobile && (
            <div className="text-containerLecture">
              <p className="textAreaLecture">
                Suite au succès du livre, récit de vie plein d’espoir et d’une énergie
                communicative, une Performance-lecture a été réalisée par la comédienne
                Lorette Andersen. Elle a condensé le récit de Mamadou Sow et le transmet
                avec brio en 40 minutes au public. Mamadou Sow, présent en bord de scène,
                répond ensuite aux questions de l’auditoire et explique comment il vient en
                aide à des personnes handicapées de son pays.
              </p>
            </div>
          )}
        </div>
        <div className="cartouche">
          {isMobile ? (
            <div className="lectureP">
              Pour assister à la Performance-lecture ou pour en organiser une dans votre institution, suivez-nous sur <a href="https://www.facebook.com/Larouteaboutdebras">Facebook</a> ou contactez-nous à <a href="mailto:larouteaboutdebras@gmail.com">larouteaboutdebras@gmail.com</a>.
            </div>
          ) : (
            <p className="cartoucheText">
              Pour assister à la Performance-lecture ou pour en organiser une dans votre institution, suivez-nous sur <a href="https://www.facebook.com/Larouteaboutdebras">Facebook</a> ou contactez-nous à <a href="mailto:larouteaboutdebras@gmail.com">larouteaboutdebras@gmail.com</a>.
            </p>
          )}
        </div>
       
      </div>
      <div className="cartoucheSoutiens">
          <br />
          <div className="logos-container">
            <img className="logo" src={logo1} alt="Logo 1" />
          </div>
        </div>
    </>
  );
};

export default PerformanceLecture;
