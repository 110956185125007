import React, { useState, useEffect } from 'react';
import './Header.css';
import imageHeader from './ImageHeader.jpg';

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="header">
      <div className="innerContainer">
        <div className="text-column">
          <h2 className={isMobile ? 'titreSoutienAssociation' : 'titre'}>
            Aide aux personnes à mobilité réduite en Guinée
          </h2>
          {isMobile ? (
            <div className='divPHeader'>
              <strong><em>La route à bout de bras (RBB)</em></strong> est une association qui envoie et distribue des fauteuils roulants en Guinée. Elle offre aussi dans son atelier à Conakry des formations pour la réparation et le réglage des fauteuils. Le but est à plus long terme d’en fabriquer sur place.
            </div>
          ) : (
            <p className='para'>
              <strong><em>La route à bout de bras (RBB)</em></strong> est une association qui envoie et distribue des fauteuils roulants en Guinée. Elle offre aussi dans son atelier à Conakry des formations pour la réparation et le réglage des fauteuils. Le but est à plus long terme d’en fabriquer sur place.
            </p>
          )}
        </div>
        <div className="video-column">
          <img className='imageHeader' src={imageHeader} alt="Aide et réglage de fauteuil à Konakry " />
        </div>
      </div>
    </header>
  );
};

export default Header;
