import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './DevenirMembre.css';

const DevenirMembre = () => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [adresse, setAdresse] = useState('');
  const [telephone, setTelephone] = useState('');
  const [cotisation, setCotisation] = useState('');
  const [remarques, setRemarques] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Envoi du formulaire à l'adresse e-mail via EmailJS
    const templateParams = {
      nom,
      prenom,
      email,
      adresse,
      telephone,
      cotisation,
      remarques
    };

    

    emailjs.send('service_h9ydrqf', 'template_qiq5piq', templateParams, 'CfrpVhBXsBREYVI2e')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Formulaire envoyé avec succès ! Une confirmation vous a été envoyé par email, pensez a regarder dans votre dossier spam.');
      }, (error) => {
        console.log('FAILED...', error);
        alert('Erreur lors de l\'envoi du formulaire.');
      });

    // Réinitialisation des champs du formulaire
    setNom('');
    setPrenom('');
    setEmail('');
    setAdresse('');
    setTelephone('');
    setCotisation('');
    setRemarques('');
  };

  return (
    <div className="devenir-membre">
      <h2>Devenir membre</h2><h5>Les champs marqués * sont obligatoires. </h5>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nom">Nom * :</label>
          <input type="text" id="nom" value={nom} onChange={(e) => setNom(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="prenom">Prénom * :</label>
          <input type="text" id="prenom" value={prenom} onChange={(e) => setPrenom(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">E-mail * :</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="adresse">Adresse * :</label>
          <input type="text" id="adresse" value={adresse} onChange={(e) => setAdresse(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="telephone">Téléphone :</label>
          <input type="tel" id="telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)}  />
        </div>
        <div className="form-group">
          <label htmlFor="cotisation">Cotisation * (minimum 20 CHF) :</label>
          <input
            type="number"
            id="cotisation"
            placeholder="Minimum 20"
            value={cotisation}
            onChange={(e) => setCotisation(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="remarques">Remarques :</label>
          <textarea id="remarques" value={remarques} onChange={(e) => setRemarques(e.target.value)}></textarea>
        </div>
        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
}

export default DevenirMembre;
