import React, { useState, useEffect } from 'react';
import './LeLivre.css';
import image1 from './A.png';
import image2 from './Z.png';
import Title from './Title';

const LeLivre = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className={`containerLeLivre ${isMobile ? 'mobile-view' : ''}`}>
        <section className='sectionExtrait'>
          <h2>Extrait de la lettre A & Z</h2>
          <img className="imgLeLivre" src={image1} alt="Extrait 1" />
          <img className="imgLeLivre" src={image2} alt="Extrait 2" />
          <div className="text-containerStory">
            <p className="signatureStory">Mamadou Sow</p>
            <p className='lienCommercial'>
              Le récit de Mamadou Sow recueilli par Elisabeth Zurbriggen est disponible en bibliothèques, en librairie
              et aux éditions <a className='migrilude'  target="_blank"  href="https://www.migrilude.com/fr/accueil/108-la-route-a-bout-de-bras-9791097542139.html" rel="noreferrer">Migrilude </a>
            </p>
          </div>
        </section>

        <section>
          <div className="video-container-LeLivre">
            <h2>Mamadou Sow raconte son odyssée :</h2>
            <iframe
              className={`iframeLeLivre ${isMobile ? 'mobile-view' : ''}`}
              title="Video 1"
              src="https://player.vimeo.com/video/453378381"
              frameBorder="0"
              allowFullScreen
            ></iframe>
            <iframe
              className={`iframeLeLivre ${isMobile ? 'mobile-view' : ''}`}
              title="Video 2"
              src="https://player.vimeo.com/video/453648806"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </section>
      </div>
    </>
  );
};

export default LeLivre;
