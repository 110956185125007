import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './Gallery.css';

const Gallery = () => {
  const [showGallery, setShowGallery] = useState(false);

  const images = [
    {
      original: 'mission1/1.JPG',
      thumbnail: '/mission1/1.JPG',
    },
    {
      original: 'mission1/2.JPG',
      thumbnail: '/mission1/2.JPG',
    },
    {
      original: 'mission1/3.JPG',
      thumbnail: '/mission1/3.JPG',
    },
    {
      original: 'mission1/4.JPG',
      thumbnail: '/mission1/4.JPG',
    },
    {
      original: 'mission1/5.JPG',
      thumbnail: '/mission1/5.JPG',
    },
    {
      original: 'mission1/6.JPG',
      thumbnail: '/mission1/6.JPG',
    },
    {
      original: 'mission1/7.JPG',
      thumbnail: '/mission1/7.JPG',
    },
    {
      original: 'mission1/8.JPG',
      thumbnail: '/mission1/8.JPG',
    },
    {
      original: 'mission1/9.JPG',
      thumbnail: '/mission1/9.JPG',
    },
    {
      original: 'mission1/10.JPG',
      thumbnail: '/mission1/10.JPG',
    },
    {
      original: 'mission1/11.JPG',
      thumbnail: '/mission1/11.JPG',
    },
    {
      original: 'mission1/12.JPG',
      thumbnail: '/mission1/12.JPG',
    },
    {
      original: 'mission1/13.JPG',
      thumbnail: '/mission1/13.JPG',
    },
    {
      original: 'mission1/14.JPG',
      thumbnail: '/mission1/14.JPG',
    },
    {
      original: 'mission1/15.JPG',
      thumbnail: '/mission1/15.JPG',
    },
    {
      original: 'mission1/16.JPG',
      thumbnail: '/mission1/16.JPG',
    },
    {
      original: 'mission1/17.JPG',
      thumbnail: '/mission1/17.JPG',
    },
    {
      original: 'mission1/18.JPG',
      thumbnail: '/mission1/18.JPG',
    },
    {
      original: 'mission1/20.JPG',
      thumbnail: '/mission1/20.JPG',
    },
  ];

  const handleGalleryClose = () => {
    setShowGallery(false);
  };

  return (
    <div className="gallery-container">
      <h2 className='Title'>Première mission à Conakry, avril 2023</h2>
      {!showGallery ? (
        <div className="thumbnail" onClick={() => setShowGallery(true)}>
          <img src={images[0].original} alt="Première mission à Conakry" className="thumbnail-image" />
          <p className="thumbnail-caption">Cliquer pour ouvrir la galerie</p>
        </div>
      ) : (
        <div className="image-gallery-container">
          <button className="close-button" onClick={handleGalleryClose}>Fermer</button>
          <ImageGallery items={images} />
        </div>
      )}
    </div>
  );
};

export default Gallery;
