import React from 'react';
import './Participer.css';
import Title from './Title';
import { Link } from 'react-router-dom';

const Participer = () => {
  return (
    <>
    <div className="participer-container">
      <h2 className="participer-title">Contribuez à notre action</h2>
      

      <div className="participer-option">
        <h3>1) Don de matériel (fauteuil roulant ou béquilles)</h3>
        <p>Offrir un fauteuil roulant, c'est offrir bien plus qu'un simple moyen de déplacement.

C‘est la possibilité de ne plus être à terre dans la boue pendant la saison des pluies. C’est plus d’autonomie pour se rendre à l’école ou chercher une activité lucrative.
<br/> <br/>
Pour donner du matériel : <a href="mailto:larouteaboutdebras@gmail.com">larouteaboutdebras@gmail.com</a></p>
      
      </div>

      <div className="participer-option">
        <h3>2) Don financier</h3>
        <p>Chaque contribution, quelle que soit sa taille, est un pas vers la réalisation de notre projet. Votre soutien financier permet l’achat de fauteuils et en particulier des fauteuils pour enfants, l’acquisition d’outillage et de matériel spécifique pour les réparations ainsi que leur envoi, l’aménagement et la location de l’atelier, les rémunérations des réparateurs valides et à mobilité réduite,...</p>
        
        <Link to="/contact"><button className='buttonMembre'>Faire un don</button></Link>
      </div>

      <div className="participer-option">
        <h3>3) Devenir membre</h3>
        <p>Pour être informé de nos activités ou y participer. Votre adhésion est un signe de solidarité et d'engagement envers les personnes à mobilité réduite de Guinée.</p>
     
        <Link to="/devenir-membre"><button className='buttonMembre'>Devenir membre</button></Link>
      </div>




    </div>
    </>
  );
};

export default Participer;
