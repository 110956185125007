import React from 'react';
import './Title.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';

const Title = () => {
  return (
    <div className="title">
      <img src={logo} alt="Logo A bout de bras" />
      <h1 className='titleMain'>La route à bout de bras</h1>
      <div className="buttonsContainer">
        <Link to="/contact">
          <button className='buttonDon'>Faire  un  don </button>
        </Link>
        <Link to="/devenir-membre">
          <button className='buttonDon'>Devenir membre</button>
        </Link>
      </div>
    </div>
  );
}

export default Title;